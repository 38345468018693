import { observable, action, makeObservable, computed } from 'mobx';
import SaveableStore from './SaveableStore';

export class BaseRequestStore extends SaveableStore {

    // auth properties
    bearerToken: string | null = null;
    expires: string | null = null;
    renewToken: string | null = null;
    mfaToken: string | null = null; // Don't store in session storage. Only for initial login

    // counter
    requestCount: number = 0;

    constructor() {
        super('BaseRequestStore');
        makeObservable(this, {
            bearerToken: observable,
            expires: observable,
            renewToken: observable,
            requestCount: observable,

            addRequest: action,
            removeRequest: action,
            resetStore: action,

            requestInProgress: computed
        })

        // important to init session storage
        this.initSessionStorage(this, ['bearerToken', 'expires', 'renewToken']);
    }

    addRequest() {
        this.requestCount++;
    }

    removeRequest() {
        this.requestCount = this.requestCount < 0 ? 0 : this.requestCount - 1;
    }

    get requestInProgress() {
        return this.requestCount > 0;
    }

    resetStore() {
        this.bearerToken = null;
        this.expires = null;
        this.renewToken = null;
        this.requestCount = 0;
        this.mfaToken = null;
    }
}

export const baseRequestStore = new BaseRequestStore();