import { router, routes } from "components/Routes";
import { action, makeObservable, observable } from "mobx";
import { UserInterface } from "types/UserInterface";
import { BearerTokenResponseInterface } from "types/BearerTokenResponseInterface";
import { BaseStore } from './BaseStore';
import request from 'helpers/Request';
import { baseRequestStore } from './BaseRequestStore';
import { selectedCompanyStore } from './SelectedCompanyStore';
import { RoleEnum } from 'types/RoleEnum';

export class AuthStore extends BaseStore {

    user: UserInterface | null = null;

    constructor() {
        super('AuthStore');

        makeObservable(this, {
            user: observable,

            resetStore: action,
            authenticate: action,
            changePassword: action,
            logout: action,
            resetPassword: action,
            setPassword: action
        })

        this.initSessionStorage(this, ['user']);
    }

    resetStore = () => {
        this.user = null;
        baseRequestStore.resetStore();
    }

    authenticate = async (email: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                // send request
                const res = await request.post('auth/token', {
                    username: email,
                    password: password
                });

                if (!res.data) {
                    reject('Invalid email or password');
                    return;
                }

                // pick data from response
                const responseBody = res.data as BearerTokenResponseInterface;

                // set data in requestStore
                baseRequestStore.bearerToken = responseBody.bearerToken;
                baseRequestStore.expires = responseBody.expires;
                baseRequestStore.renewToken = responseBody.renewToken!;
                baseRequestStore.mfaToken = responseBody.mfaToken;

                this.user = responseBody.user;
                selectedCompanyStore.setUserCompanies(responseBody.user.companies);

                resolve(true);

            } catch (err) {
                reject(err);
            }
        })
    }

    authenticateMfa = (mfaToken: string, mfaCode: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                // send request
                const res = await request.post('auth/token-finish', {
                    mfaToken: mfaToken,
                    mfaCode: mfaCode
                });

                if (!res.data) {
                    reject('Invalid email or password');
                    return;
                }

                // pick data from response
                const responseBody = res.data as BearerTokenResponseInterface;

                // set data in requestStore
                baseRequestStore.bearerToken = responseBody.bearerToken;
                baseRequestStore.expires = responseBody.expires;
                baseRequestStore.renewToken = responseBody.renewToken!;
                baseRequestStore.mfaToken = null;

                this.user = responseBody.user;
                selectedCompanyStore.setUserCompanies(responseBody.user.companies);

                resolve(true);
            }
            catch (err) {
                reject(err);
            }
        })
    }

    changePassword = (currentPassword: string, newPassword: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                // send request
                const res = await request.post('auth/change-password', {
                    currentPassword: currentPassword,
                    newPassword: newPassword
                });

                if (res.status === 401) {
                    reject('Invalid password');
                    return;
                }

                // pick data from response
                const responseBody = res.data as BearerTokenResponseInterface;

                // set data in requestStore
                baseRequestStore.bearerToken = responseBody.bearerToken;
                baseRequestStore.expires = responseBody.expires;
                baseRequestStore.renewToken = responseBody.renewToken!;

                resolve(true);

            } catch (err) {
                reject(err);
            }
        })
    }

    renewToken = async (renewToken: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post('auth/renew-token', {
                    renewToken: renewToken
                });

                if (!res.data) {
                    reject('Invalid renew token');
                }

                const responseBody = res.data as BearerTokenResponseInterface;

                // update bearer token data in requestStore
                baseRequestStore.bearerToken = responseBody.bearerToken;
                baseRequestStore.expires = responseBody.expires;

                await this.get(`members/me`, 'admin');
                resolve(true);

            } catch (err) {
                reject(err);
            }
        })
    }

    resetPassword = (email: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`auth/forgot-password`, {
                    username: email
                })

                if (res.status === 200) {
                    resolve(true);
                }
                else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    resendPassword = (userIds: Array<string>) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`auth/resend-passwords`, {
                    userIds: userIds
                })

                if (res.status === 200) {
                    resolve(true);
                }
            }
            catch (error) {
                reject(error);
            }
        })
    }

    setPassword = (token: string, email: string, password: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`auth/reset-password`, {
                    username: email,
                    password: password,
                    resetPasswordToken: token
                })

                if (res.status === 200) {
                    resolve(true);
                }
                else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    getDownloadToken = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.get('auth/download-token');
                if (res.data) {
                    resolve(res.data);
                } else reject();
            }
            catch (error) {
                reject(error);
            }
        })
    }

    logout = () => {
        this.resetStore();
        selectedCompanyStore.resetStore();
        router.navigate(routes.pageLogin);
    }

    createCompany = (companyName: string, redirect: boolean = true) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await request.post(`companies`, {
                    name: companyName
                })

                if (res.data) {
                    if (redirect) {
                        const userCompany = {
                            role: RoleEnum.ADMIN,
                            company: res.data,
                            id: res.data.id
                        };

                        this.user?.companies.push(userCompany);
                        selectedCompanyStore.setUserCompanies([...selectedCompanyStore.userCompanies, ...[userCompany]]);
                        selectedCompanyStore.setSelectedCompany(userCompany.company);

                        router.navigate(routes.pageFront);
                    }

                    resolve(true);
                }
            }
            catch (error) {
                reject(error)
            }
        })
    }

    get isLoggedIn() {
        return this.user !== null;
    }
}

export const authStore = new AuthStore();