import Button from 'components/Forms/Button';
import Input from 'components/Forms/Input';
import { useTranslation } from 'react-i18next';
import NotLoggedInWrapper from 'components/NotLoggedInWrapper';
import { useNavigate } from 'react-router-dom';
import { routes } from 'components/Routes';
import Form from 'components/Forms/Form';
import { authStore } from 'stores/AuthStore';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { baseRequestStore } from 'stores/BaseRequestStore';

enum FormEnum {
    EMAIL = 'email',
    PASSWORD = 'password'
}

interface FormInterface {
    [FormEnum.EMAIL]: string,
    [FormEnum.PASSWORD]: string
}

const PageLogin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.isLoggedIn) {
            authStore.logout();
        }
    }, [])

    const onSubmit = async (data: FormInterface) => {
        try {
            await authStore.authenticate(data[FormEnum.EMAIL], data[FormEnum.PASSWORD]);
            navigate(routes.pageFront);
        }
        catch (error) {
            if (!baseRequestStore.mfaToken) {
                toast(t('wrongEmailOrPassword'), { type: 'error' })
                return;
            }

            const mfaCode = window.prompt(t('2fa')!);
            if (!mfaCode) return;

            try {
                await authStore.authenticateMfa(baseRequestStore.mfaToken, mfaCode);
                navigate(routes.pageFront);
            }
            catch (err) {
                toast(t('wrongEmailOrPassword'), { type: 'error' })
                baseRequestStore.mfaToken = null;
            }

        }
    }

    return (
        <NotLoggedInWrapper>
            <Form
                validation={(yup) => ({
                    [FormEnum.EMAIL]: yup.string().required('*'),
                    [FormEnum.PASSWORD]: yup.string().required('*')
                })}
                onSubmit={onSubmit}>
                <div className='grid gap-4 '>
                    <Input
                        label='E-mail'
                        name={FormEnum.EMAIL}
                    />
                    <Input
                        autoComplete='new-password'
                        label='Password'
                        name={FormEnum.PASSWORD}
                        type='password'
                    />
                    <div className='flex items-center place-content-between'>
                        <div>
                            <button
                                type='button'
                                onClick={() => navigate(routes.pageForgotPassword)}
                                className='underline text-gray-600'>
                                {t('forgotPassword')}
                            </button>
                        </div>
                        <Button
                            appearance='primary'
                            type='submit'
                            label={t('signIn')}
                        />
                    </div>
                </div>
            </Form>
            <div className='mt-12'>
                <div className='text-center border-t'>
                    <button
                        onClick={() => navigate(routes.pageSignUp)}
                        type='button'
                        className='text-gray-600 mt-6 inline'>
                        <span className='text-blue-900 pb-2 inline-block'>{t('newOnSafetyObserver')}</span>
                        <span className='underline block'>{t('clickHereToGetStarted')}</span>
                    </button>
                </div>
            </div>
        </NotLoggedInWrapper>
    )
}

export default PageLogin;